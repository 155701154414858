.container {
  position: sticky;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  width: 400px;
  height: 250px;
  border: 1px solid #c72833;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.title {
  color: rgba(8, 7, 7, 0.8);
  font-family: Monaco;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}
.main {
  color: rgba(8, 7, 7, 0.8);
  font-family: Monaco;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
}
.linkTel {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-family: Monaco;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.linkTel:hover,
.linkTel:active,
.linkTel:focus {
  color: #000;
  text-decoration: none;
}
.bottom {
  color: #c72833;
  font-family: Monaco;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
@media screen and (max-width: 500px) {
  .container {
    width: 90%;
    height: 250px;
  }
}
