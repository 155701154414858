body {
  margin: 0;
  font-family: ui-monospace, "Menlo", "Monaco", "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace, "Museo-Cyrl",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
    monospace;
}
