.container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  margin-top: 50px;
  background-color: rgb(248, 248, 248);
  border-top: 1px solid rgba(145, 145, 145, 0.2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.infoSocial {
  width: 200px;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.imgLinkSocial {
  width: 130px;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}
.imgLinkFacebook {
  width: 30px;
  height: 30px;
  background-image: url("./assets/facebook.svg.png");
  background-size: 30px 30px;
}
.imgLinkInstagram {
  width: 30px;
  height: 30px;
  background-image: url("./assets/instagram.png");
  background-size: 30px 30px;
}
.imgLinkTelegram {
  width: 30px;
  height: 30px;
  background-image: url("./assets/telegram.png");
  background-size: 30px 30px;
}
.copyrightText {
  font-family: monospace;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
}
.contacts {
  width: 200px;
  height: 40px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}
.textContacts {
  font-family: monospace;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
}
.linksContacts {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.linkTel {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-family: Consolas;
  font-size: 13px;
  font-style: normal;
  font-weight: 100;
}
.linkTel:hover,
.linkTel:active,
.linkTel:focus {
  color: #000;
  text-decoration: none;
}
