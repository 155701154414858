.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.title {
  margin: 20px 0 20px 65px;
  width: fit-content;
  text-align: center;
  height: fit-content;
  font-family: "Menlo";
  font-size: 2rem;
  font-style: normal;
  font-weight: bold;
  color: rgba(8, 7, 7, 0.8);
}

.topContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}
.containerItem {
  width: 390px;
  height: 180px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 1px 2px rgba(148, 148, 148, 0.5),
    0 2px 3px rgba(93, 93, 93, 0.5);
}

.itemImg {
  width: 150px;
  height: 130px;
  border-radius: 10px;
}
.textContainer {
  width: 45%;
  height: 170px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.topText {
  width: 100%;
  height: 30px;
  font-family: Monaco;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
}
.mainText {
  width: 100%;
  height: fit-content;
  font-family: Monaco;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 200;
  line-height: 25px;
}

.hrLine {
  width: 90%;
  margin: 15px auto;
  border: 1px solid rgba(224, 224, 224, 0.3);
}
.contentContainer {
  width: 92%;
  margin: 15px auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
}
.aboutUsContent {
  display: flex;
  flex-flow: column nowrap;
}
.aboutUsHeader {
  height: 60px;
  font-family: Menlo;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: bold;
}
.textTitle {
  margin: 35px 0 20px 0;
  font-family: Menlo;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: bold;
}
.textTitleCategory {
  margin: 25px 0 10px 0;
  font-family: Menlo;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: bold;
}
.textInfo {
  font-family: Monaco;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 200;
  line-height: 25px;
}
.textInfoSpecial {
  margin: 20px 0 20px 10px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-family: Monaco;
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 200;
  line-height: 25px;
  border-left: 2px solid rgba(208, 208, 208, 0.4);
  box-sizing: border-box;
  padding: 5px;
  padding-left: 20px;
  background-color: rgba(235, 235, 235, 0.5);
}
.contentImageOne {
  margin: 20px auto 20px;
  width: 500px;
  height: 300px;
  background-image: url("./assets/01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.contentImageTwo {
  margin: 20px auto 20px;
  width: 480px;
  height: 240px;
  background-image: url("./assets/02.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.contentImageThree {
  margin: 20px auto 20px;
  width: 480px;
  height: 230px;
  background-image: url("./assets/03.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
@media screen and (max-width: 500px) {
  .title {
    margin: 10px auto 10px;
    font-size: 1.5rem;
  }
  .contentImageOne {
    width: 300px;
    height: 148px;
    background-size: cover;
  }
  .contentImageTwo {
    width: 300px;
    height: 148px;
    background-size: cover;
  }
  .contentImageThree {
    width: 300px;
    height: 142px;
    background-size: contain;
  }
}
