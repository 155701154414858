.container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.topContainer {
  width: 90%;
  max-width: 1440px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.topInfo {
  width: 100%;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.linkTel {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-family: Consolas;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
}
.linkTel:hover,
.linkTel:active,
.linkTel:focus {
  color: #000;
  text-decoration: none;
}
.text {
  margin-left: 20px;
  color: rgba(52, 52, 52, 0.9);
  cursor: pointer;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.mainInfo {
  width: 100%;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.logo {
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
.mainText {
  width: 100px;
  text-align: center;
  height: fit-content;
  margin-left: 20px;
  color: rgba(8, 7, 7, 0.8);
  cursor: pointer;
  font-family: Monaco;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
}
@media screen and (min-width: 1000px) {
  .mainText {
    width: 220px;
    font-size: 12px;
  }
}
